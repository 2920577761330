'use client';

import styled from 'styled-components';

import notFound from '@/public/error.jpeg';
import { backgroundImageMixin } from '@/styles/BackgroundimageMixin';

interface ErrorStateProps {
  error: Error;
  reset: () => void;
}

const Error: React.FC<ErrorStateProps> = () => {
  return <Wrapper></Wrapper>;
};

const Wrapper = styled.div`
  ${backgroundImageMixin(notFound.src)}
  width:100vw;
  height: 100vh;
  background-size: auto;
`;

export default Error;
